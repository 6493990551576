<!-- 病房智能交互系统 -->
<template>
  <div class="wrapper pageBox product-wrapper">
    <Banner title="区域肿瘤防治平台" banner="https://static.zhongjiadata.com/images/20250302/43117eeb909e40f29b006ad802947674.png" describe="区域肿瘤防治数字化平台是通过现代信息技术与医疗服务，提高肿瘤预防、治疗和康复水平。通过肿瘤知识的科普宣教，提升公众防癌意识、降低可预防肿瘤发生率；扩大居民的肿瘤筛查范围实现早诊早治；为肿瘤患者提供全“治疗、康复、随访”全生命周期的闭环管理。构建“防、筛、诊、治、康”五位一体的肿瘤防治体系。" />
    <div class="product-content">
      <div class="product-name">平台目标</div>
      <div class="tumor-content">
        <div v-for="(item, index) in dataList" :key="index" class="content-item">
          <div class="item-title">
            <div class="title-bg">
              <img :src="item.src" alt="">
            </div>
            <div class="title-text">{{ item.title }}</div>
          </div>
          <div class="item-des">
            {{ item.des }}
          </div>
        </div>
      </div>
      <div class="product-name">平台总体架构</div>
      <div class="introduction-content">
        <img src="@/assets/product/tumor2.png" class="img2" alt="">
      </div>
      <div class="product-name">平台筛查体系</div>
      <div class="introduction-content">
        <img src="@/assets/product/tumor2.png" class="img3" alt="">
      </div>
    </div>
  </div>
</template>

<script>

import Banner from '@/components/Banner';
export default {
  name: '',
  components: {
    Banner
  },
  data() {
    return {
      dataList: [
        {
          src: require('@/assets/product/tumor-icon-1.png'),
          title: '普及科学知识',
          des: '加强公众对癌症的认知，改变不良的生活方式，降低致癌风险因素的影响。开展多样化的健康教育活动如专题讲座、媒体宣传等，发布权威的科普资料，引导社会形成健康的防癌意识。'
        },
        {
          src: require('@/assets/product/tumor-icon-2.png'),
          title: '提高早期发现率',
          des: '通过整合先进的筛查技术和广泛的公众教育，显著提高癌症早期发现的比例，从而改善患者的预后。推广标准化的筛查指南，建立便捷的预约和检测渠道，确保高危人群能够及时接受专业的检查。基层社区高效动员，二三级医院精准诊断，避免资源错配，提升医疗资源利用效率，构建高效癌症防治体系。'
        },
        {
          src: require('@/assets/product/tumor-icon-3.png'),
          title: '赋能智慧医疗',
          des: '沉淀海量数据，大数据处理与分析挖掘价值。分析人群地域、病种特征，优化筛查策略、精准资源投放。通过人工智能实现智能诊断、风险预测，辅助决策。智能评估癌症风险，精准指导诊疗引领癌症防治智慧升级，为医疗质量变革蓄势。'
        },
        {
          src: require('@/assets/product/tumor-icon-4.png'),
          title: '强化健康管理和随访',
          des: '为患者提供持续性的健康管理服务，包括出院后的跟踪监测和支持，帮助他们更好地应对疾病带来的长期影响设立专门的随访团队，利用远程医疗技术进行动态监控提供个性化的康复指导和心理支持。'
        }
      ]
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {

  }
};
</script>

<style lang='scss' scoped>
.product-wrapper {
  background:#f7f8fe;
  .product-content {

    padding-bottom: 240px;
  }
  .tumor-content {
    width: 1276px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .content-item {
      width: 304px;
      height: 425px;
      background: #ffffff;
      border-radius: 8px;
      overflow: hidden;
      .item-title {
        display: flex;
        align-items: center;
        padding-left: 12px;
        height: 72px;
        background-color: #0279FF;
        border-radius: 8px;
        color: #fff;
        font-size: 20px;
        font-weight: 600;
        .title-bg {
          background-color: #fff;
          width: 56px;
          height: 56px;
          background: #ffffff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 16px;
          img {
            width: 40px;
            height: 40px;
          }
        }
      }
      .item-des {
        width: 100%;
        padding: 32px 26px 0;
        color: #333;
        font-size: 16px;
      }
    }
  }
  .introduction-content {
    width: 1303px;
    margin: 0 auto;
    text-align: center;
    .img1 {
      width: 100%;
      height: 527px;;
    }
    .img2 {
      width: 1279px;
      height: 745px;
    }
    .img3 {
      width: 1279px;
      height: 703px;
    }
  }
}
</style>
